<template>
  <div class="plan">
    <dujia :data="data.dujiachang" v-if="data?.dujiachang" />
    <liuxiao :data="data.qiXiao" v-if="data?.qiXiao" />
    <bose :data="data.boSe" v-if="data?.boSe" />
    <tou :data="data.tou" v-if="data?.tou" />
    <wei :data="data.wei" v-if="data?.wei" />
    <chunxiaqiudong :data="data.cxqd" v-if="data?.cxqd" />
    <qianhou :data="data.qianHou" v-if="data?.qianHou" />
    <code24 :data="data.code24" v-if="data?.code24" />
    <wuXingZhongTe :data="data.wuXingZhongTe" v-if="data?.wuXingZhongTe" />
    <danshuang :data="data.danShuang" v-if="data?.danShuang" />
    <sanxiao :data="data.sanXiao" v-if="data?.sanXiao" />
    <yixiao :data="data.yiXiao" v-if="data?.yiXiao" />
    <daXiao :data="data.daXiaoShu" v-if="data?.daXiaoShu" />
  </div>
</template>

<script setup>
import { myHttp } from "@/request/index.js";
import { ref } from "vue";
import liuxiao from "./liuxiao.vue";
import bose from "./bose.vue";
import danshuang from "./danshuang.vue";
import tou from "./tou.vue";
import wei from "./wei.vue";
import yixiao from "./yixiao.vue";
import code24 from "./code24.vue";
import sanxiao from "./sanxiao.vue";
import daXiao from "./daXiao.vue";
import wuXingZhongTe from "./wuXingZhongTe.vue";
import chunxiaqiudong from "./chunxiaqiudong.vue";
import qianhou from "./qianhou.vue";
import dujia from "./dujia.vue";

const data = ref(null);

function loadData() {
  myHttp.get("/api/information/queryAll_1").then((res) => {
    if (res.success) {
      data.value = res.data;
      console.log(data);
    }
  });
}

loadData();
</script>

<style lang="scss" scoped>
::v-deep .plan_item{
  .plan_item_title{
    background-color: transparent !important;
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 20px;
  }
  ul{
    li{
      background-color: #fff;
      font-weight: 600;
    }
  }
}
.plan {
  .title {
    text-align: center;
    background-color: #009788;
    font-size: 25px;
    font-weight: bold;
    padding: 6px 0;
  }
  .plan_item {
    border: 1px solid #009788;
    .plan_item_advertisement {
      img {
        width: 100%;
        height: 40px;
        display: block;
      }
    }
    .plan_item_title {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      font-weight: bold;
      text-align: center;
      background-image: linear-gradient(to bottom, #b4b9b1, #f1edeb);
      padding: 3px 0;
      border-top: 2px solid #767474;
      border-bottom: 2px solid #767474;
      img {
        width: 30px;
        height: 30px;
      }
    }
    ul {
      background-color: #eee;
      li {
        text-align: center;
        padding: 5px 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        .value {
          color: red;
          .win {
            background-color: #ffff00;
          }
        }
        &:not(:last-child) {
          border-bottom: 2px solid #d2d2d2;
        }
      }
      .li_win {
        .winText {
          color: #d00880;
        }
      }
      .suck {
        text-decoration-line: line-through;
      }
    }
  }
}
</style>