<template>
  <ul class="tabs">
    <li
      :class="{ tabs_item: true, active: active == index }"
      v-for="(item, index) in state.tabs"
      :key="index"
      @click="active = index"
    >
      <p>{{ item.name }}</p>
      <p class="nextLotteryTime">{{ item.nextLotteryTime }}</p>
    </li>
  </ul>
  <div class="startlotto_nums">
    <p class="startlotto_nums_title">
      <span>
        {{ activeTab?.name }}第 <span class="colorRed">{{ data.period }}</span
        >期{{ isIng && isCustom ? "开奖中..." : "开奖结果" }}
      </span>
      <span
        class="colorRed"
        @click="router.push({ name: 'drawList', query: { type: lotteryType } })"
        >历史记录</span
      >
    </p>
    <ul class="startlotto_nums_main">
      <li v-for="(item, index) in numList" :key="index">
        <startlottoNum :num="item" :qishu="data.period"/>
      </li>
    </ul>
    <p class="startlotto_nums_bottom">
      <span class="text1 colorRed">{{ data.title }}</span>
      <span class="text2" @click="refresh">刷新</span>
    </p>
  </div>
  <van-list
    v-model:loading="loadMoreing"
    :finished="finished"
    finished-text="没有更多了"
    @load="loadMore"
    v-if="false"
  >
    <div class="imgBox">
      <div class="left">
        <template v-for="(item, index) in listPicture">
          <div
            class="left_item"
            :key="index"
            v-if="index % 2 == 0"
            @click="imagePreview(index)"
          >
            <van-image width="100%" :src="item.pictureUrl" lazy-load />
            <p class="pictureName">{{ item.pictureName }}</p>
          </div>
        </template>
      </div>
      <div class="right">
        <template v-for="(item, index) in listPicture">
          <div
            class="right_item"
            :key="index"
            v-if="index % 2 != 0"
            @click="imagePreview(index)"
          >
            <van-image width="100%" :src="item.pictureUrl" lazy-load />
            <p class="pictureName">{{ item.pictureName }}</p>
          </div>
        </template>
      </div>
    </div>
  </van-list>
  <plan />
</template>

<script setup>
import { showImagePreview } from "vant";
import startlottoNum from "@/components/startlottoNum.vue";
import plan from "@/components/plan/plan.vue";
import { drawHttp, myHttp } from "@/request/index";
import { reactive, ref, computed, watch } from "vue";
import {
  generateRandomArray,
  isTimeWithinRange,
  checkTimeGreaterThanCurrent,
  listenTime,
} from "@/utils";
import { useRouter } from "vue-router";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentDay = currentDate.getDate();
console.log(currentMonth, currentDay);

const router = useRouter();

function imagePreview(index) {
  console.log(index);
  showImagePreview({
    images: listPicture.value.map((item) => item.pictureUrl),
    startPosition: index,
  });
}

const state = reactive({
  tabs: [],
});

function getBtns() {
  drawHttp({
    method: "get",
    url: "/gallerynew/h5/index/getLotterySwitchButton",
    headers: {
      lotteryType: 2,
    },
  }).then((res) => {
    res.pop();
    res.unshift({
      name: "白澳",
      nextLotteryTime: `${currentMonth}月${currentDay}日开奖`,
      custom: true,
    });
    console.log(res, "tabs");
    state.tabs = res;
    initData();
  });
}
getBtns();

const active = ref(0);
watch(active, () => {
  if (active == 0 && timer) {
    clearInterval(timer);
  } else {
    clearInterval(timer);
  }
  console.log(timer);
  initData();
});
function change(i) {
  active.value = i;
}
const lotteryType = computed(() => {
  return state.tabs.length ? state.tabs[active.value].lotteryType : 2;
});

const isCustom = computed(() => {
  console.log(state.tabs[active.value]?.custom);
  return state.tabs[active.value]?.custom;
});

const activeTab = computed(() => {
  return state.tabs[active.value];
});

const data = ref({});
const numList = computed(() => {
  return data.value.originalDataList || [];
});

function loadData() {
  drawHttp
    .get(
      `/gallerynew/h5/index/lastLotteryRecord/V2?lotteryType=${lotteryType.value}`
    )
    .then((result) => {
      data.value = result;
      console.log(result);
    })
    .catch((err) => {
      console.log(err);
    });
}

function loadData_zidingyi() {
  myHttp("/api/liuhe/queryLast").then((res) => {
    console.log(res);
    const { data: myData } = res;
    const originalDataList = [
      myData.code1,
      myData.code2,
      myData.code3,
      myData.code4,
      myData.code5,
      myData.code6,
      myData.code7,
    ];
    const period = myData.number;
    const title = myData.nextDrawPrizeDate;
    const lotteryTime = myData.drawPrizeDate;
    const obj = {
      originalDataList,
      period,
      title,
      lotteryTime,
    };
    data.value = obj;
    kaijiang_ing(obj.originalDataList);
  });
}

//开奖动画逻辑区域

const animateStateTime = "20:30:01";
const animateEndTime = "20:31:00";
const code1IngTime = "20:30:09";
const code2IngTime = "20:30:15";
const code3IngTime = "20:30:26";
const code4IngTime = "20:30:31";
const code5IngTime = "20:30:40";
const code6IngTime = "20:30:50";
const code7IngTime = "20:30:59";
listenTime(animateStateTime, () => {            
  if (isCustom.value) {
    getBtns();
  }
});
let timer;
const isIng = ref(false);
function kaijiang_ing(list) {
  timer = setInterval(() => {
    isIng.value = isTimeWithinRange(animateStateTime, animateEndTime);
    const isNum1Ing = checkTimeGreaterThanCurrent(code1IngTime);
    const isNum2Ing = checkTimeGreaterThanCurrent(code2IngTime);
    const isNum3Ing = checkTimeGreaterThanCurrent(code3IngTime);
    const isNum4Ing = checkTimeGreaterThanCurrent(code4IngTime);
    const isNum5Ing = checkTimeGreaterThanCurrent(code5IngTime);
    const isNum6Ing = checkTimeGreaterThanCurrent(code6IngTime);
    const isNum7Ing = checkTimeGreaterThanCurrent(code7IngTime);
    if (isIng.value) {
      let numList = [];
      if (isNum1Ing) {
        numList = [...list.slice(0, 0), ...generateRandomArray(1, 49, 7)];
      } else if (isNum2Ing) {
        numList = [...list.slice(0, 1), ...generateRandomArray(1, 49, 6)];
      } else if (isNum3Ing) {
        numList = [...list.slice(0, 2), ...generateRandomArray(1, 49, 5)];
      } else if (isNum4Ing) {
        numList = [...list.slice(0, 3), ...generateRandomArray(1, 49, 4)];
      } else if (isNum5Ing) {
        numList = [...list.slice(0, 4), ...generateRandomArray(1, 49, 3)];
      } else if (isNum6Ing) {
        numList = [...list.slice(0, 5), ...generateRandomArray(1, 49, 2)];
      } else if (isNum7Ing) {
        numList = [...list.slice(0, 6), ...generateRandomArray(1, 49, 1)];
      } else {
        numList = [...list.slice(0, 7), ...generateRandomArray(1, 49, 0)];
      }
      data.value.originalDataList = numList;
    } else {
      clearInterval(timer);
    }
  }, 200);
}

function initData() {
  if (!isCustom.value) {
    loadData();
  } else {
    loadData_zidingyi();
  }
}

const pageNum = ref(1);
const listPicture = ref([]);
function getlistPicture(isRefresh = false) {
  if (isRefresh) {
    pageNum.value = 1;
  }
  drawHttp({
    method: "get",
    url: `/gallerynew/h5/index/listPicture?pageNum=${pageNum.value}`,
    headers: {
      lotteryType: lotteryType.value,
    },
  }).then((res) => {
    console.log(res);
    if (isRefresh) {
      listPicture.value = [];
    }
    listPicture.value.push(...res.list);
    loadMoreing.value = false;
  });
}

const finished = ref(false);
const loadMoreing = ref(true);
function loadMore() {
  console.log("loadMore");
  pageNum.value++;
}

function refresh() {
  initData();
}
</script>

<style lang="scss" scoped>
.tabs {
  background-color: #faf6f6;
  padding: 16px 8px 0;
  display: flex;
  .tabs_item {
    flex: 1;
    text-align: center;
    background-color: rgba(232, 119, 119, 0.692);
    padding: 6px 0 3px;
    border-radius: 3px 3px 0 0;
    .nextLotteryTime {
      font-size: 11px;
    }
    p {   
      font-size: 14px;
    }
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
  .active {
    background-color: red;
    color: #fff;
  }
}
.startlotto_nums {
  background-color: #fff;
  border-top: 5px solid red;
  //   border-bottom: 5px solid #75e3b7;
  padding: 10px 12px 0;
  p {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  .startlotto_nums_main {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    li {
      &:last-child {
        margin-left: 12px;
      }
    }
  }
  .startlotto_nums_bottom {
    margin: 6px 0 12px;
    .text2 {
      color: #fff;
      background-color: red;
      padding: 3px 10px;
      border-radius: 10px;
    }
  }
}
.imgBox {
  display: flex;
  > div {
    flex: 1;
    > div {
      border-radius: 6px;
      overflow: hidden;
      min-height: 120px;
      box-shadow: 0px 0px 11px -6px #000;
      .pictureName {
        text-align: center;
      }
    }
  }
  .left {
    > div {
      margin: 0 6px 6px 0;
    }
  }
  .right {
    > div {
      margin: 0 0 6px 0;
    }
  }
}
</style>