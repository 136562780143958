<template>
  <div class="dujia">
    <ul>
      <p class="title">独家长跟久赢</p>
      <li v-for="(item, index) in data.list" :key="index">
        <div class="jingxuan">
          精选：
          <span
            :style="{ background: code == item.code ? 'yellow' : '' }"
            v-for="(code, codeIndex) in item.yuCe.code"
            :key="codeIndex"
            >{{ code
            }}{{ codeIndex + 1 == item.yuCe.code.length ? "" : "." }}</span
          >
        </div>
        <div class="sx">
          <div>{{ item.qiShu }}期:3️⃣肖</div>
          <div>
            <span
              :style="{
                background: getNumSx1(item.code, item.qiShu) == sx ? 'yellow' : '',
              }"
              v-for="(sx, sx3Index) in item.yuCe.sx3"
              :key="sx3Index"
              >{{ sx }}</span
            >
          </div>
          <div
            :style="{
              color: item.yuCe.sx3.includes(getNumSx1(item.code, item.qiShu)) ? 'red' : '',
            }"
          >
            {{
              !item.ifZuiXin
                ? getNumSx1(item.code, item.qiShu) +
                  item.code +
                  `${
                    item.yuCe.sx3.includes(getNumSx1(item.code, item.qiShu)) ? "中" : "错"
                  }`
                : "等开"
            }}
          </div>
        </div>
        <div class="sx">
          <div>{{ item.qiShu }}期:5️⃣肖</div>
          <div>
            <span
              :style="{
                background: getNumSx1(item.code, item.qiShu) == sx ? 'yellow' : '',
              }"
              v-for="(sx, sx5Index) in item.yuCe.sx5"
              :key="sx5Index"
              >{{ sx }}</span
            >
          </div>
          <div
            :style="{
              color: item.yuCe.sx5.includes(getNumSx1(item.code, item.qiShu)) ? 'red' : '',
            }"
          >
            {{
              !item.ifZuiXin
                ? getNumSx1(item.code, item.qiShu) +
                  item.code +
                  `${
                    item.yuCe.sx5.includes(getNumSx1(item.code, item.qiShu)) ? "中" : "错"
                  }`
                : "等开"
            }}
          </div>
        </div>
        <div class="sx">
          <div>{{ item.qiShu }}期:7️⃣肖</div>
          <div>
            <span
              :style="{
                background: getNumSx1(item.code, item.qiShu) == sx ? 'yellow' : '',
              }"
              v-for="(sx, sx7Index) in item.yuCe.sx7"
              :key="sx7Index"
              >{{ sx }}</span
            >
          </div>
          <div
            :style="{
              color: item.yuCe.sx7.includes(getNumSx1(item.code, item.qiShu)) ? 'red' : '',
            }"
          >
            {{
              !item.ifZuiXin
                ? getNumSx1(item.code, item.qiShu) +
                  item.code +
                  `${
                    item.yuCe.sx7.includes(getNumSx1(item.code, item.qiShu)) ? "中" : "错"
                  }`
                : "等开"
            }}
          </div>
        </div>
        <div class="sx">
          <div>{{ item.qiShu }}期:9️⃣肖</div>
          <div>
            <span
              :style="{
                background: getNumSx1(item.code, item.qiShu) == sx ? 'yellow' : '',
              }"
              v-for="(sx, sx9Index) in item.yuCe.sx9"
              :key="sx9Index"
              >{{ sx }}</span
            >
          </div>
          <div
            :style="{
              color: item.yuCe.sx9.includes(getNumSx1(item.code, item.qiShu)) ? 'red' : '',
            }"
          >
            {{
              !item.ifZuiXin
                ? getNumSx1(item.code, item.qiShu) +
                  item.code +
                  `${
                    item.yuCe.sx9.includes(getNumSx1(item.code, item.qiShu)) ? "中" : "错"
                  }`
                : "等开"
            }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import top from "@/assets/img/top.gif";
import { toRefs } from "vue";
import { getNumSx } from "@/utils";

const props = defineProps({
  data: Object,
});

function getNumSx1(num, qishu) {
  if (num) {
    return getNumSx(num, qishu);
  }
}
const { data } = toRefs(props);
console.log(data.value);
</script>

<style lang="scss" scopde>
.dujia {
  border: 2px solid #d6a24f;
  padding: 12px 0;
  ul {
    .title {
      font-size: 20px;
      text-align: center;
      color: red;
      text-align: center;
      font-weight: bold;
    }
    li {
      margin-top: 12px;
      border-top: 2px solid #d6a24f;
      border-bottom: 2px solid #d6a24f;
      > div {
        background-color: #f7f7f7;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      .jingxuan {
        text-align: center;
        color: red;
        font-weight: bold;
      }
      .sx {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        > div {
          &:nth-child(1) {
            padding: 0 12px;
            background-color: #efd8ad;
          }
          &:nth-child(2) {
            color: red;
          }
          &:nth-child(3) {
            padding: 0 12px;
            background-color: #efd8ad;
          }
        }
      }
    }
  }
}
</style>