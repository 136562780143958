<template>
  <!-- <div class="head">
    <div class="left"></div>
    <div class="title">
      <img :src="logo" alt />
    </div>
    <div class="right">
      <img :src="typeTableIcon" alt @click="router.push({name:'typeTablePage'})" />
    </div>
  </div> -->
  <div class="head1">
    <p class="aomenText">
      <span>澳</span>
      <span>门</span>
    </p>
    <img class="title" src="@/assets/img/title.gif" />
    <a href="https://guhjgvf.www53923a.com/#223" target="_blank">
      <img class="img1" src="@/assets/img/gif-icon/2.gif" alt="" />
    </a>
  </div>
</template>

<script setup>
import typeTableIcon from "@/assets/img/typeTableIcon.png";
import logo from "@/assets/img/logo.png";
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 100px;
}
.img1 {
  width: 100%;
  height: 30px;
}
.head {
  width: 100vw;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 6px 10px;
  background-color: #fc9078;
  display: flex;
  flex: 1;
  justify-content: space-between;
  > div {
    width: 33.333%;
  }
  .title {
    text-align: center;
    img {
      height: 35px;
    }
  }
  .right {
    text-align: right;
    img {
      height: 35px;
      width: 65px;
    }
  }
}
.head1{
  position: relative;
  .aomenText{
    position: absolute;
    left: 157px;
    top: 5px;
    span{
      display: inline-block;
      font-weight: bold;
      background-color: #e60012;
      border-radius: 50%;
      margin-right: 1px;
      font-size: 28px;
      color: #1412f2;
    }
  }

}
</style>