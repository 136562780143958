<template>
  <div class="tuku" v-if="list.length">
    <p class="title">澳门八二站《图集》</p>
    <ul>
      <li v-for="(item,index) in list" :key="index">
        <img :src="item.pictureUrl" alt />
        <p>{{item.pictureName}}</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { drawHttp } from "@/request";
import { ref } from "vue";

const list = ref([]);

function getData(type) {
  drawHttp({
    method: "get",
    url: `/gallerynew/h5/index/listPicture?pageNum=1`,
    headers: {
      lotteryType: type
    }
  })
    .then(result => {
      console.log(result);
      list.value.push(...result.list);
    })
    .catch(err => {});
}

getData(1);

setTimeout(() => {
  getData(2);
}, 1000);
setTimeout(() => {
  getData(3);
}, 2000);
</script>

<style lang="scss" scoped>
.tuku {
  border: 4px solid blue;
  border-radius: 3px;
}
.title {
  padding: 16px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #000;
  background-color: #fcebeb;
}
ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  li {
    img {
      width: 100%;
      height: 150px;
    }
    p {
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>